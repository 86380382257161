@import "ashishdotme-ui/styles/_theme.sass";

.hero {
  background: #614385;
  background: -webkit-linear-gradient(to right, #516395, #614385);
  background: linear-gradient(to right, #516395, #614385);
}

.shorten-url-content {
  .has-addons {
    justify-content: center !important;
  }

  .shorten-url {
    width: 30%;

    input {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1472px) {
  .container {
    max-width: 1344px;
    width: 1344px;
  }
}
